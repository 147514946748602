import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
        author
        image
        lang
        url
        keywords
      }
    }
  }
`;

function SEO({ title, description, keywords, image, url, author, lang }) {
  const { pathname } = useLocation();

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const metaTitle = title || data.site.siteMetadata.title;
        const metaDescription =
          description || data.site.siteMetadata.description;
        const metaAuthor = author || data.site.siteMetadata.author;
        const metaImage = image || data.site.siteMetadata.image;
        const metaUrl = url || data.site.siteMetadata.url;
        const metaKeywords = keywords || data.site.siteMetadata.keywords;
        const metaLang = lang || data.site.siteMetadata.lang;

        return (
          <Helmet
            htmlAttributes={{ lang: metaLang }}
            title={metaTitle}
            titleTemplate="Victory Point | %s"
            meta={[
              { name: "image", content: metaImage },
              { name: "description", content: metaDescription },
              { property: "og:title", content: metaTitle },
              { property: "og:description", content: metaDescription },
              { property: "og:type", content: "website" },
              { property: "og:image", content: metaImage },
              { property: "og:url", content: metaUrl + pathname },
              { name: "twitter:card", content: "summary_large_image" },
              { name: "twitter:site", content: metaAuthor },
              { name: "twitter:creator", content: metaAuthor },
              { name: "twitter:title", content: metaTitle },
              { name: "twitter:description", content: metaDescription },
              { name: "twitter:image", content: metaImage },
              { name: "twitter:url", content: metaUrl + pathname },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? { name: "keywords", content: metaKeywords.join(", ") }
                : []
            )}
          />
        );
      }}
    />
  );
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string,
  url: PropTypes.string,
  author: PropTypes.string,
  lang: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  keywords: [],
  url: null,
  author: null,
  lang: "en",
};

export default SEO;
